<template>
  <div>
    <supervisor-form-component :supervisor="supervisor" mode="create" />
  </div>
</template>

<script>
import SupervisorFormComponent from "./supervisor-form-component.vue";
export default {
  components: {
    SupervisorFormComponent,
  },
  data() {
    return {
      supervisor: {
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        supervisor: {
          address: "",
          designation: "",
        },
      },
    };
  },
};
</script>

<style></style>
